<template lang="">
  <div v-if="this.loading">
     <pageLoader :msg='loadingMsg'/>
  </div>
  <div class="row justify-content-center">
     <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8">
        <div class="multi-quote ">
           <div class="row position-relative justify-content-center">
              <div class="col-md-4">
                <p class="request_Id d-flex flex-column gap-1 p-2 mb-2">
                  <span>CID: </span>
                  <strong>{{requestId}}</strong>
                  <img src="@/assets/images/copy-icon.svg" class="copy-icon" @click="copyToClipboard" v-if="!copySuccess"/>
                  <img src="@/assets/images/accept.png" v-if="copySuccess" />
                </p>
                 <div>
                    <div class="headingcards mb-3 d-flex justify-content-between align-items-center">
                       <div>
                          <div class="mb-2 d-flex justify-content-between align-items-center">
                             <h6 class="mb-0">{{ motorProductType === '4W-Pvt' ? 'Car Details' : 'Bike Details' }}</h6>
                             <!-- <a href="" class="editform"  @click="openEditmodal"><img src="../../assets/images/edit.svg"  />Edit</a> -->
                             <button class="editform border-0" @click="openEditmodal">Edit</button>
                          </div>
                          <p class="mb-0 d-flex flex-wrap" > 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.rtoCode}}&nbsp;&nbsp;</span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.carMake?.makeDescription}}&nbsp;&nbsp;</span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.model?.modelDesc}}&nbsp;&nbsp; </span> 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.fuelType?.fuelType.toUpperCase()}}&nbsp;&nbsp;</span> 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.variant?.variantDesc}}&nbsp;&nbsp; </span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{ formatDate(this.userData.RegDate)}} </span> 
                          </p>
                       </div>
                    </div>
                    <div class="filter" v-if="screenWidth < 768" @click="toggleContent">
                       <img src="../../assets/images/filtericon.svg"   />
                       <p class="filter-header mb-2">Sort & Filter</p>
                       <!-- <div class="counter  mb-2">{{this.quoteCategory==='Roll-Over' ? 3:2}}</div> -->
                    </div>
                 </div>
                 <div class="formobilecotent" v-if="screenWidth >= 768 || showContent">
                  <div class="headingcards">
                       <div class="d-flex gap-2 align-items-center justify-content-between" v-if="!this.idvVisibilityFlag">
                          <strong class="mb-0">IDV Cover (Insured Value)</strong>
                          <p class="idvValue d-flex align-items-center gap-1" @click="handleIDV()">
                            <span class="cardprice">&#8377;{{this.actualDeclaredIDVValue != "" ?  convertToThousandsWithKorL(this.actualDeclaredIDVValue) : 0}}</span>
                            <img src="@/assets/images/right-arrow.svg" width="12" height="12"/>
                          </p>
                       </div>
                      <div class="d-flex align-items-center justify-content-between gap-2 mb-0" v-else>
                        <strong class="mb-0">IDV Cover (Insured Value)</strong>
                        <SkeletonLoader
                          width="25%"
                          height="10"
                          class="skeletonloader my-1" style="border-radius:50px"
                        />
                      </div>
                       <div class="mt-3" v-if="this.quoteCategory=='Roll-Over'">
                          <div class="d-flex gap-2 align-items-center justify-content-between">
                            <strong class="mb-0">No Claim Bonus (NCB)</strong>
                            <p class="idvValue d-flex align-items-center gap-1" @click="handleNCB()" v-if="this.userData.applicablePercentage !== undefined">
                              <span class="cardprice">{{this.userData.applicablePercentage}}{{String(this.userData.applicablePercentage).includes('%') ? '' : '%'}}</span>
                              <img src="@/assets/images/right-arrow.svg" width="12" height="12"/>
                            </p>
                          </div>
                          <div class="ncbOverlay" v-if="changeNCB">
                            <div class="p-3 overlay_container">
                              <FilterAccordian :title="this.title" :info="this.info"  @update:selected-item="handleSelectedItem" @clearCompareList="clearCompareList" v-if="this.quoteCategory==='Roll-Over'" @handleIdvClose="closeNCB()"/>
                            </div>
                          </div>
                        </div>
                        <template v-if="this.quoteCategory=='Roll-Over'">
                          <div v-if="this.userData.OdDate" class="mt-3">
                            <div class="d-flex gap-2 align-items-center justify-content-between">
                              <strong class="mb-0">OD Policy Expiry Date</strong>
                              <p class="idvValue d-flex align-items-center gap-1">
                                <span class="cardprice">{{this.userData.OdDate}}</span>
                              </p>
                            </div>
                          </div>
                          <div v-if="this.userData.TpDate" class="mt-3">
                            <div class="d-flex gap-2 align-items-center justify-content-between">
                              <strong class="mb-0">TP Policy Expiry Date</strong>
                              <p class="idvValue d-flex align-items-center gap-1">
                                <span class="cardprice">{{this.userData.TpDate}}</span>
                              </p>
                            </div>
                          </div>
                       </template>
                       <div class="mt-3" v-if="this.quoteCategory=='New'">
                          <div class="d-flex gap-2 align-items-center justify-content-between">
                            <strong class="mb-0">Registration Date</strong>
                            <p class="idvValue d-flex align-items-center gap-1">
                              <span class="cardprice">{{this.userData.RegDate}}</span>
                            </p>
                          </div>
                        </div>
                       <div class="idvOverlay" v-if="changeIDV">
                        <div class="p-3 overlay_container">
                          <h4 class="mb-3 pb-2">IDV Cover (Insured Value)</h4>
                          <div class="d-flex gap-2 align-items-center radio_selector-idv mb-3">
                            <input 
                              type='radio'
                              id="minIDV"
                              name="idvCovers"
                              value="minimum"
                              v-model="idvSelector"
                              @change="handleIdvSelection('minimum')"
                            />
                            <label for="minIDV">Minimum IDV</label>
                            <p class="mb-0">&#8377;{{this.minIDV}}</p>
                          </div>
                          <div class="d-flex gap-2 align-items-center radio_selector-idv mb-3">
                            <input 
                              type='radio'
                              id="maxIDV"
                              name="idvCovers"
                              value="maximum"
                              v-model="idvSelector"
                              @change="handleIdvSelection('maximum')"
                            />
                            <label for="maxIDV">Maximum IDV</label>
                            <p class="mb-0">&#8377;{{this.maxIDV}}</p>
                          </div>
                          <div class="d-flex gap-2 align-items-center radio_selector-idv mb-3">
                            <input 
                              type='radio'
                              id="suggestedIDV"
                              name="idvCovers"
                              value="suggested"
                              v-model="idvSelector"
                              @change="handleIdvSelection('suggested')"
                            />
                            <label for="suggestedIDV">Suggested IDV</label>
                            <p class="mb-0">&#8377;{{this.suggestedIDV != "" ?  this.suggestedIDV : 0}}</p>
                          </div>
                          <div class="d-flex gap-2 align-items-center radio_selector-idv">
                            <input 
                              type='radio'
                              id="customIDV"
                              name="idvCovers"
                              value="custom"
                              v-model="idvSelector"
                              @change="handleIdvSelection('custom')"
                            />
                            <label for="customIDV">Choose IDV</label>
                            <p class="mb-0">&#8377;{{this.insuredDeclaredValue != "" ?  this.insuredDeclaredValue : 0}}</p>
                          </div>
                            <input
                              type="text"
                              class="form-control p-2 mt-3"
                              placeholder="Enter your IDV"
                              v-if="idvSelector=='custom'"
                              v-model="this.selectedIDV"
                              :minValue="this.minIDV"
                              :maxValue="this.maxIDV"
                              @keyup="handleChoosenIDV($event)"
                            />
                            <small class="error-text mt-1 mb-0 idvError"></small>
                            <div class="d-flex gap-2 align-items-center justify-content-end mt-4">
                              <button @click="cleareIDV()" class="btn backbutton" style="width: fit-content">Cancel</button>
                              <button class="btn ApplyButton" @click="applyIDV()">Apply</button>
                            </div>
                        </div>
                       </div>
                    </div>
                    <article v-for="(categoryData, categoryName) in uniqueAddons!=undefined && uniqueAddons[this.activePolicyType]" 
                      :key="categoryName"
                      class="FilterAccordian headingcards mt-3">
                    <header class="d-flex justify-content-between align-items-center">
                      <h6 @click="toggleCategory(categoryName)" class="question-title mb-0">
                        {{ formatCategoryName(categoryName) }}
                      </h6>
                      <button @click="toggleCategory(categoryName)" class="btn p-0">
                        <img src="../../assets/images/minus.svg" class="plusminusicon" v-show="categoryData.expand" />
                        <img src="../../assets/images/plus.svg" class="plusminusicon" v-show="!categoryData.expand" />
                      </button>
                    </header>
                    
                    <transition mode="out-in" name="fadeAndSlide">
                      <div v-show="categoryData.expand">
                        <div>
                          <div v-for="addon in categoryData.addons" :key="addon.optionName">
                            <label style="font-size: 10px;" :for="addon.optionName+'-'+addon.policyType">
                              <input
                                :id="addon.optionName+'-'+addon.policyType"
                                type="checkbox"
                                :value="addon"
                                @change="handleAddonSelection(addon, $event.target.checked)"
                                v-model="selectedUniqueAddons"
                              />
                              {{ addon.optionName }}
                            </label>
                            
                            <!-- Text Input -->
                            <input
                              v-if="addon.type === 'Text' && selectedUniqueAddons.find(add => add.optionName == addon.optionName) != undefined && this.activePolicyType==addon.policyType"
                              v-model="addon.temp_price"
                              type="text"
                              @input="setAddonInputValue(addon, $event.target.value)"
                              :id="addon.optionName+'-'+addon.policyType"
                            />
                            
                            <!-- Select Input -->
                            <select 
                              v-if="addon.type === 'Single-Select' && selectedUniqueAddons.find(add => add.optionName == addon.optionName) != undefined && this.activePolicyType==addon.policyType"
                              v-model="addon.temp_price"
                              @input="setAddonInputValue(addon, $event.target.value)"
                              :id="addon.optionName+'-'+addon.policyType"
                            >
                              <option value="" disabled selected>Select</option>
                              <option 
                                v-for="valueOfAddon in addon.values" 
                                :key="valueOfAddon.value"
                                :value="valueOfAddon.value"
                              >
                                {{valueOfAddon.text}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </article>  
                  <button v-if="uniqueAddons!=undefined && Object.keys(uniqueAddons).length > 0" class="btn commonButton mt-3" @click="getMultiQuote()">Apply Addons</button>
                 </div>
              </div>
              <div class="col-md-8 formobileonly pl-0">
                 <h6 class="commonclass">{{this.responseData.filter(data => data.insuranceAndProducts.policyType == activePolicyType).length}} Quotes Found</h6>
                 <nav class="mb-2">
                  <div class="navigation-policytypes-wrapper" v-if="this.policyTypes.length > 1">
                    <div class="navigation-policytypes d-flex align-items-center p-1" id="nav-tab" role="tablist">
                      <button v-for="(policyType, index) in this.policyTypes" 
                        :key="policyType" 
                        class="nav-link px-3 py-2" 
                        :class="{'active': activePolicyType == policyType}" 
                        :id="policyType" 
                        @click="setPolicyType(policyType)">
                        {{policyType}}
                      </button>
                    </div>
                  </div>
                </nav>
                 <div class="d-grid gap-3">
                    <div v-if="responseData.length>0">
                      <!-- <h6>{{this.plansWithAddons[0]?.title}}</h6> -->
                      <div
                      class="headingcards row justify-content-between"
                      v-for="item in responseData.filter(data => data.insuranceAndProducts.policyType == activePolicyType)"                      :key="item?.supportingData?.insCompanyLogo"
                      >
                       <div class="col-md-8 col-8">
                         <div class="img-container">
                             <img
                                :src="resolveImagePath(item?.supportingData?.insCompanyLogo)"
                                alt="Bank Logo" class="quote-logo" draggable="false"
                                />
                              </div>
                          <div
                          class="d-flex align-items-center flex-wrap justify-content-start gap-1 mt-1"
                             >
                             <p>Policy type - </p>
                             <span>{{item.insuranceAndProducts.policyType}}</span>
                            </div>
                            <div class="d-flex flex-wrap gap-2 mt-2">
                              <div class="d-flex flex-wrap gap-2 mt-2">
                            <!-- Display Addons for the Matching Product Code -->
                            <div>
                              <!-- Addons List -->
                              <div
                                  class="d-flex align-items-center gap-1 specifications"
                                  v-for="addon in sortedAddons(item.insuranceAndProducts.productCode)"
                                  :key="addon.addonName"
                                >
                                  <p>
                                    {{ addon.addonName.optionName }} - 
                                  </p>
                                  <p v-if="Number(addon.premium) > 0">
                                    &#8377;{{ Number(addon.premium).toFixed(2) }}
                                  </p>
                                  <img
                                    :src="addon.premium > 0 
                                      ? 'https://img.icons8.com/3d-fluency/94/checkmark.png' 
                                      : 'https://img.icons8.com/3d-fluency/94/delete-sign.png'"
                                    class="commonicon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                       </div>
                       <div class="col-md-4 col-4">
                        <button
                            class="clickbuttons d-flex gap-3 align-items-center"
                            style="margin: auto"
                            @click="getPolicydetails(item)"
                          >
                            &#8377;&nbsp;
                            {{currency(
                              Number(
                                item.productDetails.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicODPremiumValue
                              ) +
                              Number(
                                item.productDetails.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].basicTPPremiumValue
                              ) -
                              Number(
                                item.productDetails.totalPremiumDetails[0].totalPremiumValueDiscount
                              ) +
                              Number(
                                item.productDetails.totalPremiumDetails[0].totalPremiumValueLoading
                              )+
                              (this.plansWithAddons.find(plan => plan.productCode === item.insuranceAndProducts.productCode)?.totalAddonsPremium || 0)
                              
                              )}}
                            <img src="../../assets/images/multiquotearrow.svg" class="commonicon" />
                          </button>

                            <div
                            class="d-flex align-items-center flex-wrap justify-content-center gap-1 mt-1"
                            >
                            <p>IDV Cover</p>
                            <!-- <span>{{!item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span> -->
                            <span>{{item.insuranceAndProducts.policyType=='StandaloneTP(1)' ? 0 : !item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span>
                          </div>
                          <div class="text-center mt-2">
                            <a :href="item.supportingData.Messages.brochureLink" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Download Brochure">
                              <img src="../../assets/images/pdf.svg" class="pdficon"  />
                            </a>
                          </div>
                          <div class="text-center mt-2">
                            <label>
                              <input type="checkbox" v-model="compareList" :value=item> <span>Add To Compare</span>
                             </label>
                          </div>
                        </div>
                      </div>
                      
                      <h6>{{this.plansWithAddons[1]?.title}}</h6>
                      <div
                      class="headingcards row justify-content-between"
                      v-for="item in this.plansWithAddons[1]?.plans"
                      :key="item?.supportingData?.insCompanyLogo"
                      >
                       <div class="col-md-8 col-8">
                          <div class="img-container">
                             <img
                                :src="resolveImagePath(item?.supportingData?.insCompanyLogo)"
                                alt="Bank Logo" class="quote-logo" draggable="false"
                                />
                          </div>
                          <div
                             class="d-flex align-items-center flex-wrap justify-content-start gap-1 mt-1"
                             >
                             <p>Policy type - </p>
                             <span>{{item.insuranceAndProducts.policyType}}</span>
                          </div>
                          <div class="d-flex flex-wrap gap-2 mt-2">
                             <div
                                class="d-flex align-items-center gap-1 specifications"
                                v-for=" usp in this.extractUSP(item?.supportingData)"
                                :key="usp"
                                >
                                <img src="../../assets/images/checksvg.svg" class="commonicon"  />
                                <p>{{ usp }}</p>
                             </div>
                          </div>
                       </div>
                       <div class="col-md-4 col-4">
                          <button
                             class="clickbuttons d-flex gap-3 align-items-center"
                             style="margin: auto"
                             @click="this.getPolicydetails(item)"
                             > &#8377;&nbsp;{{currency(item.productDetails.totalPremiumDetails[0].totalPremiumWithTax)}}
                          <img src="../../assets/images/multiquotearrow.svg" class="commonicon"   />
                          </button>
                          <div
                             class="d-flex align-items-center flex-wrap justify-content-center gap-1 mt-1"
                             >
                             <p>IDV Cover</p>
                             <!-- <span>{{!item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span> -->
                             <span>{{item.insuranceAndProducts.policyType=='StandaloneTP(1)' ? 0 : !item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span>
                          </div>
                          <div class="text-center mt-2">
                             <a :href="item.supportingData.Messages.brochureLink" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Download Brochure">
                             <img src="../../assets/images/pdf.svg" class="pdficon"  />
                             </a>
                          </div>
                          <div class="text-center mt-2">
                            <label>
                              <input type="checkbox" v-model="compareList" :value=item> <span>Add To Compare</span>
                             </label>
                          </div>
                       </div>
                    </div>
                  </div>
                    <!-- Card End -->
                    <!-- data not found -->
                    <div v-if="displayErrorMessages">
                      <p class="mt-3 errorMessage" :innerHTML="displayErrorMessages" />
                    </div>
                    <div class="data-not-found" v-if="this.responseData.length==0 && this.pollapiflag && !this.loading2">
                       <div v-if="!this.loading2">
                          <img src="/images/data-not-found.gif" />
                          <p>No products available</p>
                       </div>
                    </div>
                    <!-- <MultiquoteLoader/> -->
                    <div v-if="this.loading2" class="text-center w-100">
                      <img src="/images/multiquoteloader.gif" class="mt-5" style="width:60%" />
                      <p class="loading-quote">Loading more quotes for you. This may take few minutes</p>
                       <!-- <MultiquoteLoader/> -->
                       <!-- <pageLoader :msg='loadingMsg'/> -->
                    </div>
                     <!-- -------------------------Showing the Loading Message--------------------- -->
                     <!-- <span class="loading_message" v-if="this.loading2">Please wait a moment while we get more quotes. It will take a few minutes.</span> -->
                    <!-- -------------------------Showing the Loading Message--------------------- -->
                 </div>
              </div>
           </div>
           <span class="compare-error">{{compareErrror}}</span>
           <button @click="openCompareQuote" class="compare-button">compare <span>{{compareList.length}}</span> </button>
           <div class="errors-button d-flex flex-column align-items-end">
            <transition name="fade">
              <div class="error-display mb-3" v-if="errorListFlag && errors.length > 0">
                <div v-for="(manufacturer, index) in groupedErrors" :key="index" class="manufacturer-group my-1 p-2">
                  <div 
                    class="manufacturer-header" 
                    @click="toggleManufacturer(manufacturer.id)"
                  >
                    <span class="toggle-icon">{{ manufacturer.isOpen ? '▼' : '▶' }}&nbsp;</span>{{ manufacturer.id }}&nbsp;({{manufacturer.products.length}})
                  </div>
                  <transition name="fade">
                    <div v-if="manufacturer.isOpen" class="product-errors">
                      <div v-for="product in manufacturer.products" :key="product.productId" class="product-error my-2">
                        <strong>{{ product.productId }}</strong>: {{ product.errorMessage }}
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
            <button @click="openErrorList" class="d-flex align-items-center gap-2">
              <span>Errors</span>
              <div class="loader" v-if="pollapiflag"></div>
              <span v-if="!pollapiflag">{{errors.length}}</span>
             </button>
           </div>
           <div>
            
           </div>
        </div>
       
     </div>
  </div>
  <CompareQuote v-if="CompareQuoteFlag" @closeCompareQuote='closeCompareQuote' :compareList=compareList  @getPolicydetails=getPolicydetails></comparequote>
  <editDetailsModal v-if="showeditmodal" @close-Editmodal="closeEditmodal"></editDetailsModal>
</template>
<script>
// import RangeSlider from "@/components/multiQuote/priceRangeSlider.vue";
import FilterAccordian from "@/components/multiQuote/filterAccordian.vue";
import editDetailsModal from "@/modals/editDetailsModal.vue";
import CompareQuote from "@/modals/comparequote.vue"
// import loading from "../pageLoaders/pageLoader.vue";
import { getLazyLoadingConfig } from '@/mixins/common';
import pageLoader from "../pageLoaders/pageLoader.vue";
import alasql from 'alasql';
import MultiquoteLoader from "../pageLoaders/multiquoteLoader.vue";
import { SkeletonLoader } from "vue3-loading-skeleton";
import {getDistributer} from '@/mixins/common';


export default {
  components: {
    // RangeSlider,
    FilterAccordian,
    editDetailsModal,
    pageLoader,
    MultiquoteLoader,
    SkeletonLoader,
    CompareQuote 
  },
  mixins:[getDistributer],
  data() {
    return {
      selected_plan_data: null,
      quote_request: null,
      apiCallinginterval: '',
      rmData: null,
      changeIDV: false,
      actualDeclaredIDVValue: 0,
      info: "What is the No Claim Bonus (NCB%) in your current policy?",
      idvSelector: 'suggested',
      changeNCB: false,
      compareList:[],
      CompareQuoteFlag:false,
      errorListFlag: false,
      uniqueAddons:[],
      selectedUniqueAddons:[],
      plansWithAddons:[],
      showAll: false,
      showAllCardAddons:false,
      distributorID:'',
      policyExpand: true,
      addonExpand:true,
      title1: "Policy Types",
      policyTypes: [],
      policyTypesSelected: [],
      onMountFlag: true,
      changeIDV: false,
      insuredDeclaredValue: 0,
      actualDeclaredIDVValue: 0,
      insuredDeclaredValues: [],
      selectedItem: null,
      title: "NCB",
      info: "What is the No Claim Bonus (NCB%) in your current policy?",
      selectedIDV: 0,
      maxIDV: 0,
      minIDV: 0,
      suggestedIDV: 0,
      productCodes: [],
      apiCallinginterval: '',
      loadingMsg: "Please wait while we are fetching the data",
      loading: true,
      loading2: true,
      responseData: [],
      responseData1: [],
      requestId: "",
      requestBody: {},
      pollapiflag: true,
      screenWidth: window.innerWidth,
      showContent: false,
      idvVisibilityFlag: false,
      refetchIDVonClick: false,
      userData: {},
      rmData: {},
      quoteCategory: '',
      previousPolicyTpStartDatenew: '',
      previousPolicyTpExpiryDatenew: '',
      breakTheLoop: false,
      displayErrorMessages: "",
      showeditmodal:false,
      compareErrror:"",
      totalAddonsPremium:0,
      activePolicyType: "",
      groupedErrors: [],
      errors: [],
      idvSelector: 'suggested',
      changeNCB: false,
      addonsMQ: [],
      motorCovers: [
            {
              "optionSelected": "",
              "optionValue": [
                {
                  "optionValueDescription": "addOnSumAssured",
                  "optionValueInput": "100"
                }
              ],
              "varFields": [
                {
                  "fieldName": "",
                  "fieldValue": ""
                }
              ]
            }
      ]
    };
  },
  mounted() {
    this.distributorID = getDistributer()["distributerID"];
    this.loading2 = true;
    sessionStorage.removeItem("rtoCode")
    //get Interval 
    this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
    this.quoteCategory = this.$store.state.quoteCategory;
    this.activePolicyType = (this.motorProductType=='4W-Pvt' && this.quoteCategory=='Roll-Over') ? "Package(1+1)" : (this.motorProductType=='4W-Pvt' && this.quoteCategory=='New') ? "Bundled(1+3)" : (this.motorProductType=='2W-Pvt' && this.quoteCategory=='Roll-Over') ? "Package(1+1)" : (this.motorProductType=='2W-Pvt' && this.quoteCategory=='New') ? "Bundled(1+5)" : "" ;
    this.addonsMQ = this.$store.state.configData.ADDONS_MASTER[this.motorProductType];
    // this.formatMqAddonsInCategory();
    //get Multi quote
    // Update screenWidth on resize
    window.addEventListener("resize", this.updateScreenWidth);
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      if (this.userData.TpDate) {
        this.previousPolicyTpStartDatenew = this.userData.TpDate === "" ? this.calculatePreviousPolicyTpStartDate(this.userData.OdDate) : this.calculatePreviousPolicyTpStartDate(this.userData.TpDate);
        this.previousPolicyTpExpiryDatenew = this.userData.TpDate === "" ? this.previousPolicyTpExpiryDate(this.userData.OdDate) : this.userData.TpDate;
      }
      else {
        this.previousPolicyTpStartDatenew = null,
          this.previousPolicyTpExpiryDatenew = null
      }
    }
    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    this.getMultiQuote();
    this.idvVisibilityFlag = true;
    sessionStorage.removeItem('idvValues');
  },
  beforeUnmount() {
    // Remove event listener on component destroy
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    formatAddons() {
      const result = {};
      for (const [policyType, addons] of Object.entries(this.filterPolicies(this.addonsMQ, this.policyTypes))) {
        result[policyType] = this.categorizeAddons(addons);
      }
      this.uniqueAddons = result;      
      this.formattedAddons = result;
    },
    categorizeAddons(addons) {
      const categories = {};

      addons.forEach(addon => {
        const category = addon.category || 'Other';
        if (!categories[category]) {
          categories[category] = { expand: false, addons: [] };
        }
        addon.temp_price = addon.default;
        categories[category].addons.push(addon);
      });

      return categories;
    },
    filterPolicies(data, allowedTypes) {
      const filteredData = {};
      for (const [policyType, policyData] of Object.entries(data)) {
        if (allowedTypes.includes(policyType)) {
          filteredData[policyType] = policyData;
        }
      }
      // console.log("Filtered data---------------", filteredData);
      return filteredData;
    },
    formatMqAddonsInCategory() {
      try {
        // this.motorProductType
        const parsedData = typeof this.addonsMQ === 'string' ? JSON.parse(this.addonsMQ) : this.addonsMQ;
        const packageKeys = Object.keys(parsedData);

        // Initialize empty category structure
        const categoryStructure = {};
        let packageFound = false;
        for (const packageKey of packageKeys) {
          if (parsedData[packageKey][this.activePolicyType]) {
            packageFound = true;
            parsedData[packageKey][this.activePolicyType].forEach(item => {
              const category = item.category || 'Other';
              // Dynamically create category if it doesn't exist
              if (!categoryStructure[category]) {
                categoryStructure[category] = {
                  expand: false,
                  addons: []
                };
              }
              categoryStructure[category].addons.push(item);
            });
          }
        }
        if (!packageFound) {
          console.warn(`Package "${this.activePolicyType}" not found`);
          this.uniqueAddons = {};
          return this.uniqueAddons;
        }
        // Remove empty categories
        Object.keys(categoryStructure).forEach(key => {
          if (categoryStructure[key].addons.length === 0) {
            delete categoryStructure[key];
          }
        });
        this.uniqueAddons = categoryStructure;
        return this.uniqueAddons;
      } catch (error) {
        console.error('Error formatting package data:', error);
        this.uniqueAddons = {};
        return this.uniqueAddons;
      }
    },
    formatCategoryName(name) {
      // Convert camelCase or other formats to readable text
      // Example: "AdditionalAddon" becomes "Additional Addon"
      return name
        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
        .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
        .trim();
    },
    toggleCategory(categoryName) {
      console.log("Category", categoryName);
      
      if (this.uniqueAddons[this.activePolicyType][categoryName]) {
        this.uniqueAddons[this.activePolicyType][categoryName].expand = !this.uniqueAddons[this.activePolicyType][categoryName].expand;
      }
    },
    refetchPremium(name){
      let allAddonSelected = [];
      allAddonSelected = this.selectedUniqueAddons.map(item => {
        
        return {
          optionSelected: item.optionName || "",
          optionValue: [
            {
              optionValueDescription: item.optionDesc,
              optionValueInput: item.temp_price
            }
          ],
          varFields: [
            {
              fieldName: "",
              fieldValue: ""
            }
          ]
        };
      });
      this.motorCovers = allAddonSelected;
    },
    closeNCB(){
      console.log("NCB modal closed")
      this.changeNCB = false;
    },
    handleIDV(){
      // this.insuredDeclaredValue = this.suggestedIDV;
      if(this.actualDeclaredIDVValue == this.minIDV || this.actualDeclaredIDVValue == this.maxIDV || this.actualDeclaredIDVValue == this.suggestedIDV){
        this.idvSelector = this.actualDeclaredIDVValue == this.suggestedIDV ? 'suggested' : this.actualDeclaredIDVValue == this.minIDV ? 'min' : this.actualDeclaredIDVValue == this.maxIDV ? 'max' : 'custom';
        this.insuredDeclaredValue = this.suggestedIDV;
      }else{
        this.idvSelector = 'custom';
        this.insuredDeclaredValue = this.actualDeclaredIDVValue;
      }
      this.selectedIDV = this.actualDeclaredIDVValue;
      this.changeIDV = true;
    },
    setPolicyType(policyType){
      this.activePolicyType = policyType;
      // this.formatMqAddonsInCategory()
    },
    handleNCB(){
      this.changeNCB = true;
    },
    sortedAddons(productCode) {
    const plan = this.plansWithAddons.find(plan => plan.productCode === productCode);
    if (!plan) return []; // Return an empty array if no plan matches

    // Sort add-ons: first by premium > 0 (non-zero premiums first), then by premium value (high to low)
    return plan.addons
      .slice()
      .sort((a, b) => (b.premium > 0) - (a.premium > 0) || b.premium - a.premium);
  },
    clearCompareList(){
      this.compareList=[];
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.requestId)
        .then(() => {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 2000);
        })
        .catch(err => console.error("Failed to copy text: ", err));
    },
    openCompareQuote(){
      if(this.compareList.length<2 || this.compareList.length>3){
        this.compareErrror="add 2 or 3 plan for compare"
        return;
      }
      this.compareErrror="";
      this.CompareQuoteFlag=true;
    },
    openErrorList(){
      this.errorListFlag = !this.errorListFlag;
      if(this.errorListFlag == false){
        this.groupedErrors.map(m => m.isOpen = false);
      }
    },
    closeCompareQuote(){
      this.CompareQuoteFlag=false;
    },
    showAllAddons() {
      this.showAll = true;
    },
    showAllCardAddon() {
      this.showAllCardAddons = !this.showAllCardAddons;
    },
    hideAddons() {
      this.showAll = false;
    },

//     filterPlansWithAddons() {
//   // Initialize `plansWithAddons` to avoid accumulation of old data
//   this.plansWithAddons = [
//     { title: "Filtered Plans", plans: [] },
//     { title: "Other Plans", plans: [] }
//   ];

//   // Loop through each plan in responseData
//   this.responseData.forEach(plan => {
//     const hasAllMatchingAddons = this.selectedUniqueAddons.every(selectedAddon =>
//       plan.productDetails.motorCovers.some(addon => addon.optionDescription === selectedAddon)
//     );

//     if (hasAllMatchingAddons) {
//       this.plansWithAddons[0].plans.push(plan); // Add to "Filtered Plans"
//     } else {
//       this.plansWithAddons[1].plans.push(plan); // Add to "Other Plans"
//     }
//   });
// }
// ,
filterPlansWithAddons() {
  // Initialize `plansWithAddons` to avoid accumulation of old data
  this.plansWithAddons = [];

  // Loop through each plan in responseData
  this.responseData.forEach(plan => {
    const productCode = plan.insuranceAndProducts.productCode;

    // Check if the productCode already exists in `plansWithAddons`
    let existingPlan = this.plansWithAddons.find(item => item.productCode === productCode);

    if (!existingPlan) {
      // If the productCode is not found, initialize it
      existingPlan = {
        productCode,
        addons: [],
        totalAddonsPremium: 0
      };
      this.plansWithAddons.push(existingPlan);
    }

    // Process only addons present in the product's motorCovers
    this.selectedUniqueAddons.forEach(selectedAddon => {
      // Find the addon in the current plan's motorCovers
      const matchingAddon = plan.productDetails.motorCovers.find(
        addon => addon.optionDescription === selectedAddon.optionName
      );

      if (matchingAddon) {
        // Determine the premium for the addon
        const addonPremium = matchingAddon.premiumForEachPolicyTerm?.[0]?.premiumForEachPPO?.[0]?.premiumForEachPPF?.[0]?.totalPremiumValue || 0;

        // Check if the addon is already in the addons array
        const existingAddon = existingPlan.addons.find(addon => addon.addonName === selectedAddon);

        if (!existingAddon) {
          // Push the addon details to the addons array
          existingPlan.addons.push({
            addonName: selectedAddon,
            premium: addonPremium
          });

          // Accumulate the total premium for the product
          existingPlan.totalAddonsPremium += addonPremium;
        }
      }
    });
  });
      
      // // Calculate the overall total premium across all plans
      // this.totalAddonsPremium = this.plansWithAddons.reduce(
      //   (sum, plan) => sum + plan.totalAddonsPremium,
      //   0
      // );
      sessionStorage.setItem("plansWithAddons",JSON.stringify(this.plansWithAddons));
      this.selectedUniqueAddons = this.selectedUniqueAddons.filter(item => typeof item !== 'string' && item !== null && typeof item === 'object')
      console.log("Updated plansWithAddons:", this.plansWithAddons);
      console.log("Total Addons Premium across all products:", this.totalAddonsPremium);
    },

    // Method to handle checkbox change
    handleAddonSelection(selectedAddon, isChecked) {
      console.log(isChecked,">>>>>>>>>>>>>>>>",selectedAddon)
      if (isChecked) {
      // Check if the add-on is already in the list
      const alreadyExists = this.selectedUniqueAddons.some(
        addon => addon.addonName === selectedAddon.optionName
      );
      console.log("Existing-------------  ", alreadyExists, isChecked, selectedAddon.optionName);
      

      if (!alreadyExists) {
        // Add the add-on to the selected list only if it's not already present
        this.selectedUniqueAddons.push(selectedAddon.optionName);
      }
    } else {
        // Remove the addon from the selected list
        this.selectedUniqueAddons = this.selectedUniqueAddons.filter(addon => addon !== selectedAddon.optionName);

      }
      this.selectedUniqueAddons = this.selectedUniqueAddons.filter(item => typeof item !== 'string' && item !== null && typeof item === 'object')
      sessionStorage.setItem('selectedUniqueAddons',JSON.stringify(this.selectedUniqueAddons))
      console.log("this.selectedUniqueAddons",this.selectedUniqueAddons)
      // Recalculate filtered plans and premiums
      this.filterPlansWithAddons();
      this.refetchPremium(selectedAddon.optionName);
    },
    // setAddonInputValue(addon, value) {
    //   const found = this.motorCovers.find(
    //     i => i.optionSelected === addon.optionSelected
    //   );
    //   if (found) {
    //     this.motorCovers.find(i => i.optionSelected === addon.optionSelected).optionValue[0].optionValueInput = value;
    //     console.log("Value Found: ", value, this.motorCovers);
    //   }
    // },
    setAddonInputValue(addon, value) {
      console.log("Updated motorCovers:", this.motorCovers);
      const index = this.motorCovers.findIndex(
        i => i.optionSelected === addon.optionName

      );
      if (index !== -1) {
        // Create a new object to trigger reactivity
        const updatedCover = { ...this.motorCovers[index] };
        updatedCover.optionValue = [
          {
            ...updatedCover.optionValue[0],
            optionValueInput: value
          }
        ];
        // Use Vue.set to ensure reactivity
        console.log("Covers......... ", updatedCover);
        this.motorCovers[index] = updatedCover;
        // self.$set(this.motorCovers, index, updatedCover);
        console.log("Updated covers, ", this.motorCovers,index, updatedCover);
        
      }
      console.log("Updated motorCovers 1:", this.motorCovers);
    },
    getAddonInputValue(addon) {
      const found = this.motorCovers.find(
        i => i?.optionSelected === addon.optionName
      );
      return found?.optionValue[0]?.optionValueInput || '';
    },

    openEditmodal(){
   this.showeditmodal=true
    },
    closeEditmodal(){
   this.showeditmodal=false
    },
    // Reload IDV slider Container
    reloadIDVcontainer(){
      this.idvVisibilityFlag = true;
      setTimeout(() => {
        this.idvVisibilityFlag = false;
      }, 500);
    },
    // Date fromatting to DD-MM-YYYY Fromat
    formatDate(date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    //Clear Policy Filter
    clearPolicyFilter() {
      this.responseData = this.responseData1;
      this.policyTypesSelected = this.policyTypes;
    },

    //Apply Policy Type Filter
    applyPolicyType() {
      this.clearCompareList()
      this.responseData = this.responseData1.filter(item => this.policyTypesSelected.includes(item.insuranceAndProducts.policyType));
    },
    //Cleare IDV

      //Cleare IDV
      cleareIDV() {
      if(this.insuredDeclaredValue == this.minIDV || this.insuredDeclaredValue == this.maxIDV || this.insuredDeclaredValue == this.suggestedIDV){
        this.idvSelector = 'suggested';
        this.insuredDeclaredValue = this.suggestedIDV;
        this.changeIDV = false;
      }else{
        this.insuredDeclaredValue = this.suggestedIDV;
        this.idvSelector = 'custom';
        this.changeIDV = false;
      }
    },
    //Apply IDV
    applyIDV() { 
      if(this.selectedIDV < this.minIDV || this.selectedIDV > this.maxIDV){
        return;
      }
      this.requestBody.product.idvDetails.insuredDeclaredValue = this.selectedIDV;
      sessionStorage.setItem('quote_request',JSON.stringify(this.requestBody))
      this.clearCompareList();
      this.changeIDV = false;
      this.breakTheLoop = true;
      this.pollapiflag = false;
      // this.idvSelector = 'suggested';
      this.getMultiQuote();
      this.idvVisibilityFlag = true;
      this.refetchIDVonClick = true;
      this.loading2 = true;

        this.getMultiQuote(this.selectedIDV);


    },
    //Handel Selected Item
    handleSelectedItem(value) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      this.selectedItem = value; // Update the parent component's data
      this.pollapiflag = false;
      this.getMultiQuote();
    },
    resolveImagePath(variable) {
      return variable || "";
    },
    // Get Selected IDV all API
    // getSelectedIDV() {
    //   //product.idvDetails.insuredDeclaredValue
    //   this.insuredDeclaredValue = this.selectedIDV
    //   // this.minIDV = this.selectedIDV;
    // },
    // Handle IDV Selection on Range
    handleIdvSelection(selected) {
      if(selected == 'minimum'){
        this.insuredDeclaredValue = this.minIDV;
        this.selectedIDV = this.minIDV;
      }else if(selected == 'maximum'){
        this.insuredDeclaredValue = this.maxIDV;
        this.selectedIDV = this.maxIDV;
      }else if(selected == 'suggested'){
        this.insuredDeclaredValue = this.suggestedIDV;
        this.selectedIDV = this.suggestedIDV;
      }else if(selected == 'custom'){
        // this.insuredDeclaredValue = this.selectedIDV;
        // this.suggestedIDV = this.selectedIDV;
      }
      if(this.selectedIDV >= this.minIDV && this.selectedIDV <= this.maxIDV ){
        document.querySelector('.idvError').style.display = 'none'
        document.querySelector('.idvError').innerHTML = ""
      }else{
        document.querySelector('.idvError').style.display = 'block';
        document.querySelector('.idvError').innerHTML = `Enter IDV value between &#8377;${this.minIDV} and &#8377;${this.maxIDV}`;
      }
      this.requestBody.product.idvDetails.insuredDeclaredValue = this.selectedIDV;
      sessionStorage.setItem('quote_request',JSON.stringify(this.requestBody))
      sessionStorage.setItem('idvValues', JSON.stringify({
        selectedIDV: this.selectedIDV,
        declaredIDV: this.insuredDeclaredValue
      }));
    },
    handleChoosenIDV(e){
      if( this.selectedIDV >= this.minIDV && this.selectedIDV <= this.maxIDV){
        document.querySelector('.idvError').style.display = 'none'
        document.querySelector('.idvError').innerHTML = ""
      }else{
        document.querySelector('.idvError').style.display = 'block';
        document.querySelector('.idvError').innerHTML = `Enter IDV value between &#8377;${this.minIDV} and &#8377;${this.maxIDV}`;
      }
      this.insuredDeclaredValue = this.selectedIDV;
      sessionStorage.setItem('idvValues', JSON.stringify({
        selectedIDV: this.selectedIDV,
        declaredIDV: this.insuredDeclaredValue
      }));
    },
    //Convert in Thousand with K
    convertToThousandsWithKorL(number) {
      const number1 = parseFloat(number);
      if (number1 >= 100000) {
        // 1 lakh (100,000) or more
        return (number1 / 100000).toFixed(1) + 'L';
      } else if (number1 >= 1000) {
        // 1 thousand (1,000) or more
        return (number1 / 1000).toFixed(1) + 'k';
      }
      return number1.toString();
    },

    //Extract USP Array
    extractUSP(obj) {
      const uspArray = [];
      for (let key in obj) {
        if (key.startsWith('usp') && obj[key] && obj[key] !== null && obj[key] !== undefined) {
          uspArray.push(obj[key]);
        }
      }
      return uspArray;
    },
    getPolicydetails(data) {
      this.loading = true;
      this.pollapiflag = true;
      sessionStorage.setItem("selectedUniqueAddons",JSON.stringify(this.selectedUniqueAddons))
      sessionStorage.setItem("selected_plan_data", JSON.stringify(data))
      this.pollapiflag = false;
      this.setUpSQ();
    },
    //Get Polling Data
    pollData() {
      // this.disableButtons = true;
        if (this.pollapiflag == true) {
          this.getMotorProduct();
        } else {
          this.loading2 = false;
          if (this.responseData?.length == 0) {
            this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          }
          this.beforeDestroy();
        }
    },
    //Get Polling Data
    pollDataSQ() {
    if (this.pollapiflagSQ == true) {
      this.getMotorProductSQ();
    } else {
      this.loading2 = false;
      if (this.responseData?.length == 0) {
        this.erMsg = 'No products found as per your selected filter. Please change the filters.';
      }
      this.beforeDestroy();
    }
    },
    //Cleare Interval
    beforeDestroy() {
      this.disableButtons = false;
    },
    //Convert Currency 
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    sleep(ms){
      return new Promise(resolve  => setTimeout(resolve, ms))
    },
    //Polling API
    async getMotorProduct() {
      this.activePolicyType = (this.motorProductType=='4W-Pvt' && this.quoteCategory=='Roll-Over') ? "Package(1+1)" : (this.motorProductType=='4W-Pvt' && this.quoteCategory=='New') ? "Bundled(1+3)" : (this.motorProductType=='2W-Pvt' && this.quoteCategory=='Roll-Over') ? "Package(1+1)" : (this.motorProductType=='2W-Pvt' && this.quoteCategory=='New') ? "Bundled(1+5)" : "" ;
      this.loading2 = true;

      // this.suggestedIDV = 0;
      let headers = { "Authorization": `Bearer ${this.$store.state.token}` };
      let reqData = [this.requestId];
      const startTime = Date.now();
      const maxPollingTime = 3 * 60 * 1000;
      while (this.pollapiflag && (Date.now() - startTime) < maxPollingTime) {
        try {
          if(this.$route.path !== "/policy-quote"){
            this.pollapiflag = false;
            break;
          }
          // if(this.breakTheLoop){
          //   break;
          // }
          console.log("Inside Get Motor Product misssing")
          const response = await this.backendApi("quotationPoll", reqData, headers, "get");
          if (response?.data?.data?.quote?.length > 0) {
            const quotes = response?.data?.data?.quote;
            if(this.refetchIDVonClick){
              this.idvVisibilityFlag = true;
              for (let i = 0; i < quotes?.length; i++) {
                const suggestedIDV = quotes[i]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
                if (suggestedIDV) {
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
                if (suggestedIDV==0 && quotes.length==1) {
                  this.suggestedIDV = suggestedIDV;
                  // this.insuredDeclaredValue = suggestedIDV;
                  this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
              }
            }
            if (this.onMountFlag) {
              for (let j = 0; j < quotes?.length; j++) {
                const suggestedIDV = quotes[j]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
                if (suggestedIDV) {
                  this.suggestedIDV = suggestedIDV;
                  // this.insuredDeclaredValue = suggestedIDV;
                  // this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
                if (suggestedIDV==0 && quotes.length==1) {
                  this.suggestedIDV = suggestedIDV;
                  // this.insuredDeclaredValue = suggestedIDV;
                  // this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
              }
            }
            response?.data?.data?.quote?.forEach((ele) => {
              if (!this.productCodes.includes(ele.insuranceAndProducts.productCode)) {
                this.productCodes.push(ele.insuranceAndProducts.productCode)
                for (let i = 0; i < ele.productDetails.motorCovers?.length; i++) {
                  // console.log("motercovers:",ele.productDetails.motorCovers[i])
        // Check if optionSelected exists and is not already in uniqueAddons
        // if (ele.productDetails.motorCovers[i].optionDescription && !this.uniqueAddons.includes(ele.productDetails.motorCovers[i].optionDescription)) {
        //     this.uniqueAddons.push(ele.productDetails.motorCovers[i].optionDescription);
        // }
              }
    // console.log("uniqueAddons",this.uniqueAddons)
                this.responseData.push(ele)
                this.responseData1.push(ele)
              }
            });
          }
          const values = this.responseData.map(quote => quote.supportingData.insuredDeclaredValue);
          const values1 = values.filter(obj => Object.values(obj).some(value => value != null ? value.toString().trim() != "" : 0))
          this.insuredDeclaredValues = values1.map(obj => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, Number(value)])))
          // AlaSQL query to find the minimum maximumIDV
          const maxIDV1 = alasql('SELECT MAX(maximumIDV) AS minMaxIDV FROM ?', [this.insuredDeclaredValues]);
          // this.maxIDV = this.convertToThousandsWithKorL(maxIDV1[0].minMaxIDV);
          this.maxIDV = isNaN(maxIDV1[0].minMaxIDV) ? 0 : parseInt(maxIDV1[0].minMaxIDV);
          const minIDV1 = alasql('SELECT MIN(minimumIDV) AS minimumIDV FROM ?', [this.insuredDeclaredValues]);
          // this.minIDV =this.convertToThousandsWithKorL(minIDV1[0].minimumIDV);
          this.minIDV = isNaN(minIDV1[0].minimumIDV) ? 0 : parseInt(minIDV1[0].minimumIDV);
          // Extract policyType values
          const policyTypes1 = this.responseData.map(item => item.insuranceAndProducts.policyType);
          // Get unique policyType values
          this.policyTypes = [...new Set(policyTypes1)];
          // this.filterPolicies(this.addonsMQ, this.policyTypes);
          this.formatAddons();
          this.policyTypesSelected = JSON.parse(JSON.stringify(this.policyTypes))
          let sessionIDVs = JSON.parse(sessionStorage.getItem('idvValues'));
          if(sessionStorage.getItem("selectedUniqueAddons")){
          this.selectedUniqueAddons = JSON.parse(sessionStorage.getItem('selectedUniqueAddons'))
          }
          if(sessionStorage.getItem('plansWithAddons')){
          this.plansWithAddons = JSON.parse(sessionStorage.getItem('plansWithAddons'))
          console.log("inside planswithAddons in quote call")
            this.filterPlansWithAddons()
          }
          if(sessionIDVs){
            this.selectedIDV = sessionIDVs.selectedIDV;
            this.insuredDeclaredValue = sessionIDVs.declaredIDV;
            this.actualDeclaredIDVValue = sessionIDVs.declaredIDV
          }else{
            this.insuredDeclaredValue = this.suggestedIDV;
            this.selectedIDV = this.suggestedIDV;
            this.actualDeclaredIDVValue = this.suggestedIDV
          }
          // Stop Polling
          if (response.data.data.isPollComplete == true) {
            this.loading2 = false;
            this.pollapiflag = false;
            this.idvVisibilityFlag = false;
            // this.reloadIDVcontainer();
            this.beforeDestroy();
            response.data.errors.forEach(errObj => this.errors.push(errObj));
            this.groupErrors()
            break;
          }
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID") {
              this.pollapiflag = false;
              break;
            }
          }
          await this.sleep(1000);
        } catch (error) {
          console.log('error msg block', error);
          this.loading = false;
        }
      };
      if ((Date.now() - startTime) >= maxPollingTime) {
        this.pollapiflag = false;
        this.loading2 = false;
        this.loading = false;
      }
    },
    //Get Multi quote Data
    getMultiQuote(selectedIDV=this.suggestedIDV) {
      
      var self = this;
      self.loading2 = true;
      self.idvVisibilityFlag = true;
      this.requestBody =
      {
        "typeOfQuote": "Multi-Quote",
        "quoteCategory": this.quoteCategory,
        "alternateFreqRequired": "No",
        "outOfBoundConfig": "No",
        "additionalSetup": {
          "currency": "",
          "userGeoLocation": {
            "latitude": null,
            "longitude": null
          },
          "userCountry": null,
          "userRegion": null,
          "userLanguage": null,
          "userIP": null,
          "userAgent": null
        },
        "distributor": {
          "distributorID": getDistributer()["distributerID"],
          "salesChannel": "Online",
          "agentID": "FSC0123786",
          "channelType": this.rmData.RoleId !== "1" ? "B2B" : "B2C",
          "varFields": [
            {
              "fieldName": "string",
              "fieldValue": "string"
            }
          ]
        },
        "personalInformation": {
          "individualDetails": [
            {
              "memberType": "Proposer",
              "memberSequenceNumber": 1,
              "nomineeRelWithInsured": null,
              "title": null,
              "firstName": null,
              "middleName": null,
              "lastName": null,
              "gender": "Male",
              "dateOfBirth": null,
              "age": null,
              "maritalStatus": null,
              "qualification": "Graduate",
              "occupation": "Salaried",
              "annualIncome": null,
              "email": "abcxyz@gmail.com",
              "mobileNumber": parseInt(this.userData.mobileNumber),
              "zipCode": this.userData.pinCode,
              "state": null,
              "city": null,
              "address1": null,
              "address2": null,
              "address3": null,
              "smoker": false,
              "residentStatus": null,
              "ownerBelongsToOrganizationFlag": null,
              "memberGST": null,
              "drivingLicenseNumber": null,
              "drivingExperienceYears": null,
              "varFields": [
                {
                  "fieldName": "string",
                  "fieldValue": "string"
                }
              ]
            }
          ]
        },
        "product": {
          "productType": "motor",
          "motorProductType": this.motorProductType,
          "insuranceAndProducts": [
            {
              "insuranceCompanyCode": "",
              "policyType": "",
              "productCode": [
                ""
              ]
            }
          ],
          "policyTerm": 1,
          "policyTermUnit": null,
          "premiumPaymentOption": null,
          "premiumPaymentTerm": null,
          "premiumPaymentFrequency": null,
          "planOption": {
            "planId": null
          },
          "registrationDetails": {
            "registrationNumber": this.userData.vehicleNumber ? this.userData.vehicleNumber : "New",
            "registrationDate": this.userData.RegDate,
            "registrationYear": null,
            "registrationMonth": null,
            "registrationDay": null,
            "manufacturingMonth": null,
            "manufacturingYear": (this.userData.MfgYear).toString(),
            "dateOfPurchase": this.userData.RegDate,
            "autoMembershipFlag": null,
            "autoMembershipNumber": null,
            "pucDeclaration": null,
            "pucNumber": null,
            "pucExpiryDate": null
          },
          "rtoLocationCode": this.userData.rtoCode,
          "vehicleDetails": {
            "vehicleUniqueCode": this.userData.vehicleKey,
            "vehicleMakeCode": this.userData.carMake.makeCode,
            "vehicleModelCode": this.userData.model.modelCode,
            "vehicleVariantCode": this.userData.variant.variantCode,
            "vehicleFuelType": this.userData.fuelType.fuelType,
            "vehicleSeatingCapacity": null,
            "vehicleCubicCapacity": null,
            "vehicleColor": null,
            "vehicleEngineNumber": null,
            "vehicleChassisNumber": null,
            "vehicleEnergyType": null,
            "vehicleWheelsChairAccess": null,
            "vehicleEmissionStandard": null,
            "vehicleWheelsCount": null,
            "vehicleCargoVolume": null
          },
          "idvDetails": {
            "insuredDeclaredValue": selectedIDV,
            "sideCarInsuredDeclaredValue": null
          },
          "ncbDetails": {
            "claimLastYearFlag": this.userData.previous_year_claim,
            "numberOfClaims": this.userData.previous_year_claim ? 1 : 0,
            "ncbPercentageLastYear": parseInt(this.userData.previous_year_claim ? 0 : this.userData.applicablePercentage)
          },
          "previousPolicyDetails": {
            "previousCoverageType": "",
            "IsPreviousPolicyExpired": this.userData.OdDate ? (this.userData.OdDate < this.currentDate ? true : false) : null,
            "previousPolicyEndDate": this.userData.OdDate === "" ? null : this.userData.OdDate,
            "previousPolicyStartDate": this.userData.OdDate === "" ? null : this.calculatePreviousPolicyStartDate(this.userData.OdDate),
            "previousPolicyInsurer": null,
            "previousPolicyNumber": null,
            "previousPolicyOdStartDate": this.userData.OdDate === "" ? null : this.calculatePreviousPolicyStartDate(this.userData.OdDate),
            "previousPolicyOdExpiryDate": this.userData.OdDate === "" ? null : this.userData.OdDate,
            "previousPolicyOdInsurer": null,
            "previousPolicyOdNumber": null,
            "previousPolicyTpStartDate": this.previousPolicyTpStartDatenew,
            "previousPolicyTpExpiryDate": this.previousPolicyTpExpiryDatenew,
            "previousPolicyTpInsurer": null,
            "previousPolicyTpNumber": null
          },
          "motorCovers": this.motorCovers
        }
      }
      sessionStorage.setItem("quote_request", JSON.stringify(this.requestBody));
      this.backendApi("quotationApi", this.requestBody, { "Authorization": `Bearer ${this.$store.state.token}` }, "post")
        .then((response) => {
          if (response.data.errors.length > 0) {
            this.loading = false;
            if (response.data.errors[0].errorDisplayMessage != "") {
              for (let i = 0; i < response.data.errors.length; i++) {
                let ck = 1;
                this.displayErrorMessages += ck + '. ' + response.data.errors[i]["errorDisplayMessage"] + '<br/>';
                ck++;
                this.loading2 = false;
                this.idvVisibilityFlag = false;
              }
            }
          } else if (response && response.data) {
            this.requestId = response.data.reqId;
            sessionStorage.setItem("journey_id", JSON.stringify(response.data.data.journey_id));
            this.loading = false;
            if (response?.data?.data?.quote?.length > 0) {
              // this.suggestedIDV = response?.data?.data?.quote[0]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
              // this.insuredDeclaredValue = this.suggestedIDV;
              response?.data?.data?.quote?.forEach((ele) => {

                if (!this.productCodes.includes(ele.insuranceAndProducts.productCode)) {
                  this.productCodes.push(ele.insuranceAndProducts.productCode)
                  this.responseData.push(ele)

                }
              });
            }
            // const insuredDeclaredValues = this.responseData.map(quote => quote.supportingData.insuredDeclaredValue);
            // // AlaSQL query to find the minimum maximumIDV
            // const maxIDV1 = alasql('SELECT MAX(maximumIDV) AS minMaxIDV FROM ?', [insuredDeclaredValues]);
            // // this.maxIDV = this.convertToThousandsWithKorL(maxIDV1[0].minMaxIDV);
            // this.maxIDV = isNaN(maxIDV1[0].minMaxIDV) ? 0 : parseInt(maxIDV1[0].minMaxIDV);
            // const minIDV1 = alasql('SELECT MIN(minimumIDV) AS minimumIDV FROM ?', [insuredDeclaredValues]);
            // // this.minIDV =this.convertToThousandsWithKorL(minIDV1[0].minimumIDV);
            // this.minIDV = isNaN(minIDV1[0].minimumIDV) ? 0 : parseInt(minIDV1[0].minimumIDV);
            if (response?.data?.errors?.length == 0) {
              this.responseData = [];
              this.productCodes = [];
              setTimeout(function () {
                self.pollapiflag = true;
                self.pollData();
              }, 1000);
            }
            else {
              this.loading2 = false;
              if (this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                this.beforeDestroy();
              }
            }
          }

        }).catch((error) => {
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.$router.push({ path: '/error-page', query: { backUrl: 'form-Wizard', msg: msg } });
        })
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    toggleContent() {
      this.showContent = !this.showContent;
    },
    calculatePreviousPolicyStartDate(odDateString) {
      let odDate = new Date(odDateString);
      odDate.setFullYear(odDate.getFullYear() - 1);
      odDate.setDate(odDate.getDate() + 1);
      let previousPolicyStartDate = odDate.toISOString().split('T')[0];
      return previousPolicyStartDate;
    },
    previousPolicyTpExpiryDate(odDateString) {
      let odDate = new Date(odDateString);
      odDate.setFullYear(odDate.getFullYear() + 1);
      odDate.setDate(odDate.getDate() - 1);
      let previousPolicyStartDate = odDate.toISOString().split('T')[0];
      return previousPolicyStartDate;
    },
    calculatePreviousPolicyTpStartDate(tpDateString) {
      if (tpDateString) {
        let tpDate = new Date(tpDateString);
        if (this.motorProductType == "2W-Pvt") {
          tpDate.setFullYear(tpDate.getFullYear() - 5);
        }
        else
          tpDate.setFullYear(tpDate.getFullYear() - 3);
        tpDate.setDate(tpDate.getDate() + 1);
        let previousPolicyTpStartDate = tpDate?.toISOString().split('T')[0];
        return previousPolicyTpStartDate;
      }
    },
    groupErrors() {
      const grouped = this.errors.reduce((acc, error) => {
        if (!acc[error.manufacturerId]) {
          acc[error.manufacturerId] = {
            id: error.manufacturerId,
            isOpen: false,
            products: []
          }
        }
        acc[error.manufacturerId].products.push({
          productId: error.productId,
          errorMessage: error.listOfErrors[0].errorDisplayMessage || error.listOfErrors[0].errorMessage
        })
        return acc
      }, {})
      
      this.groupedErrors = Object.values(grouped)
    },
    toggleManufacturer(manufacturerId) {
      const manufacturer = this.groupedErrors.find(m => m.id === manufacturerId)
      if (manufacturer) {
        manufacturer.isOpen = !manufacturer.isOpen
      }
    },
    setUpSQ(){
      //mounted sq data
      if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.userData, "userData")
      }
      this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
      this.selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
      let spdetail_flag = process.env.VUE_APP_SPDETAIL_ONESB;

      if (spdetail_flag == 1) {
        if (sessionStorage.getItem("rm_data")) {
          this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
          this.getSPDetailsOneSB(this.rmData.Userid, this.selected_plan_data.insuranceAndProducts.insuranceCompanyCode);
        }
      }
      this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
      this.apiReqData.product.idvDetails.insuredDeclaredValue = this.selected_plan_data.supportingData.insuredDeclaredValue.suggestedIDV;

      //setupData
      this.apiReqData.typeOfQuote = "Single Quote";
      this.apiReqData.product.policyTerm = 1;
      this.apiReqData.product.insuranceAndProducts[0].insuranceCompanyCode = this.selected_plan_data.insuranceAndProducts.insuranceCompanyCode;
      this.apiReqData.product.insuranceAndProducts[0].policyType = this.selected_plan_data.insuranceAndProducts.policyType;
      this.apiReqData.product.planOption.planId = this.selected_plan_data.productDetails.planOption.planId
      this.apiReqData.product.insuranceAndProducts[0].productCode[0] = this.selected_plan_data.insuranceAndProducts.productCode
      this.apiReqData.product.previousPolicyDetails.previousCoverageType = this.selected_plan_data.productDetails.previousPolicyDetails.previousCoverageType;
      this.apiReqData.product.idvDetails.insuredDeclaredValue = this.selected_plan_data.supportingData.insuredDeclaredValue.suggestedIDV
      this.apiReqData.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
      // this.apiReqData.product.motorCovers=this.selected_plan_data.productDetails.motorCovers;
      // this.apiReqData.product.motorCovers=[];
      this.apiReqData.product.policyTerm = parseInt(this.selected_plan_data.productDetails.policyTerm);
      this.apiReqData.product.premiumPaymentFrequency = this.selected_plan_data.productDetails.premiumPaymentFrequency;
      for (var i = 0; i < this.selected_plan_data.productDetails.motorCovers.length; i++) {
        // console.log("this.selectedAddons[i].optionValue",this.selectedAddons[i]["optionValue"])
        let tempArr = {
          "optionSelected": this.selected_plan_data.productDetails.motorCovers[i]["optionSelected"],
          "optionValue": this.selected_plan_data.productDetails.motorCovers[i]["optionValue"],
          "varFields": [
            {
              "fieldName": "",
              "fieldValue": ""
            }
          ]
        };
        console.log("moter", this.responseData);
        // let itemIndex = this.responseData.productDetails.motorCovers.findIndex(item);
        this.apiReqData.product.motorCovers[i] = tempArr;
      }
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      this.apiReqData.distributor.channelType = this.rmData.RoleId !== "1" ? "B2B" : "B2C";
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.getSingleQuote();
    },
      //get single quote
      getSingleQuote() {
      var self = this;
      self.loading2 = true;
      console.log("this.requestBody", this.apiReqData)
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.backendApi("quotationApi", this.apiReqData, { "Authorization": `Bearer ${this.$store.state.token}` }, "post")
        .then((response) => {
          if (response.data.errors.length > 0) {
            this.loading = false;
            for (let i = 0; i < response.data.errors.length; i++) {
              this.displayErrors.push(response.data.errors[i]["errorDisplayMessage"])
            }
          } else if (response && response.data) {
            
            this.requestIdSQ = response.data.reqId;
            // this.loading = false;
            if (response?.data?.data?.quote?.length > 0) {
              // this.responseData.push(response.data.data.quote)

            }
            if (response.data.errors.length == 0) {
              setTimeout(function () {
                self.pollapiflagSQ = true;
                self.pollDataSQ();
              }, 1000);
            }
            else {
              this.loading2 = false;
              if (this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                self.beforeDestroy();
              }
            }
          }

        }).catch((error) => {
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.displayErrors.push(msg)
        })
    },
    getSPDetailsOneSB(rm_code, man_code) {
      let reqData = [man_code];
      let headers = {};
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
        .then((response) => {
          if (response == 'error') {
            self.apiError = true;
          } else if (response && response.data) {
            console.log('response sp details: ', response.data);
            if (response.data.status == "failed") {
              //redirection to error page and on go back button redirect to homepage
              let msg = ['SP details are not available']
              // this.$router.push({ path: '/networkalert', query: {backUrl:'multi-quote', msg: msg }});
            }
            this.spdata = response.data.data;
            //   this.quotationSQcall();
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            this.spdataFlagForSQ = true;
            this.apiReqData.distributor.agentID = this.spdata.spCode;
            for (var attr in this.spdata.attributes) {
              try {
                let varfield = {};
                varfield["fieldName"] = attr;
                varfield["fieldValue"] = this.spdata.attributes[attr];
                this.apiReqData.distributor.varFields.push(varfield);
              } catch (err) {
                console.log("varfield assignment failed", err);
              }
            }
          }
        })
        .catch((error) => {
          console.log('error msg block spdetailOneSB API', error);
          let msg = ['SP details are not available']
          // this.$router.push({ path: '/networkalert', query: {backUrl:'multi-quote', msg: msg }});
        });
    },
    async getMotorProductSQ() {
      console.log(" inside getMotorProductSQ")
      this.loading2 = true;
      const startTime = Date.now();
      const maxPollingTime = 3 * 60 * 1000;
      let headers = { "Authorization": `Bearer ${this.$store.state.token}` };
      let reqData = [this.requestIdSQ];

      while (this.pollapiflagSQ && (Date.now() - startTime) < maxPollingTime) {
        try {
          if(this.$route.path !== "/policy-quote"){
            this.pollapiflagSQ = false;
            break;
          }
          const response = await this.backendApi("quotationPoll", reqData, headers, "get");
          if (response.data.errors.length > 0) {
            this.loading = false;
            for (let i = 0; i < response.data.errors.length; i++) {
              this.displayErrors.push(response.data.errors[i]["errorDisplayMessage"])
            }
          }
          if (response?.data?.data?.quote?.length > 0) {
            this.loading2 = false;
            this.suggestedIDV = response?.data?.data?.quote[0].supportingData.insuredDeclaredValue.suggestedIDV;
            this.responseData = response.data.data.quote;
            // if(this.addFilterdataInSession){
            //   this.responseData[0].productDetails.motorCovers=this.filteredOptions
            //   this.addFilterdataInSession=false;
            // }
            sessionStorage.setItem("quoteSingleData", JSON.stringify(this.responseData[0]))
            this.$router.push('/ckyc-form');

          }
          if (response.data.data.isPollComplete == true) {
            this.loading2 = false;
            this.pollapiflagSQ = true;
            this.beforeDestroy();
            break;
          }
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID") {
            this.pollapiflagSQ = true;
            }
          }
          await this.sleep(1000);
        }catch(err){
          console.log('error msg block', err);
          this.loading = false;
        }
      };
      if ((Date.now() - startTime) >= maxPollingTime) {
        this.pollapiflagSQ = false;
        this.loading2 = false;
        this.loading = false;
      }
    },
  
  },
  computed: {
    displayedAddons() {
      // Show all add-ons if `showAll` is true, otherwise only the first 6
      // return this.showAll ? this.uniqueAddons : this.uniqueAddons.slice(0, 6);
    },
    currentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const dd = String(today.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
  },
 
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/filters.scss";
@import "../../assets/scss/components/select-card.scss";

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.fadeAndSlide-enter-from,
.fadeAndSlide-leave-to{
  opacity: 0;
}
.fadeAndSlide-enter-active,
.fadeAndSlide-leave-active{
  transition: opacity 0.3s ease-in-out;
}

.price::before {
  content: '\20B9';
  margin-right: 5px;
}
.idvError{
  color: #F00;
  font-size: 14px;
}
.request_Id{
  position: relative;
  font-size: 11px;
  color: $commonColorDark;
  border-radius: 8px;
  border: 1px solid $border-color;
  img{
    position: absolute;
    top: -2px;
    right: -2px;
    margin-left: auto;
    cursor: pointer;
    border: none;
    width: 24px;
    height: 24px;
  }
  .copy-icon{
    transform: scale(1.5);
    height: 16px !important;
  }
}
.navigation-policytypes-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 6px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.navigation-policytypes {
  background-color: $base-dark-text;
  border-radius: 6px;
  white-space: nowrap;
  min-width: min-content;

  button {
    border-radius: 4px;
    white-space: nowrap;
    flex: 0 0 auto;
    transition: background-color 0.3s ease;

    &.active {
      background-color: $bodyDarkBG;
      color: $commonColor;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
</style>
